import {
  collection, getFirestore, doc, getDoc, connectFirestoreEmulator,
  query, onSnapshot, orderBy
} from 'firebase/firestore'
export class Database {
  #just;
  #db;
  #scaffold;
  #collections;
  #subscriptions;
  #selected;
  #users;
  #cache;
  constructor(just) {
    this.#just = just;
    this.#db = getFirestore(just.app);
    this.#scaffold = {};
    this.#collections = {};
    this.#subscriptions = {};
    this.#selected = {};
    this.#users = [];
    this.#cache = {};
    if (window.location.hostname === 'localhost') {
      connectFirestoreEmulator(this.#db, '127.0.0.1', 8080);
    }
  }

  scaffoldDisplay(scaffoldId, data) {
    const scaffold = this.#just.db.scaffold(scaffoldId);
    let text = "";
    $.each(scaffold.display.label, (i, label) => {
      if (!data[label]) {
        text += label;
      } else {
        text += data[label];
      }
    });
    return text;
  }

  select(scaffold, level, keys) {
    const self = this;
    if (!self.#selected[scaffold]) {
      self.#selected[scaffold] = [];
    }
    if (keys) {
      self.#selected[scaffold][level] = keys;
    } else {
      return self.#selected[scaffold][level];
    }
  }

  users(uid) {
    const self = this;
    if (uid) {
      return self.#users.find(user => user.id === uid);
    }
    self.subscribe("user", ["display_name", "asc"], (type, data) => {
      if (type === "added") {
        self.#users.push(data);
      }
    });
  }

  subscribe(path, order, onUpdate) {
    const self = this;
    const deferred = $.Deferred();
    const unsubscribe = onSnapshot(query(collection(self.#db, path), orderBy(...order)), (snap) => {
      snap.docChanges().forEach((change) => {
        const data = change.doc.data();
        data.id = change.doc.id;
        onUpdate(change.type, data);
      });
      deferred.resolve(unsubscribe);
    });
    return deferred;
  }

  connect() {
    const self = this;
    const deferred = $.Deferred();
    self.users();
    self.#just.callFunction('getScaffolds').then((data) => {
      $.each(data, (i, scaffold) => {
        self.#scaffold[scaffold.id] = scaffold;
      });
      deferred.resolve(self.#scaffold);
    });
    return deferred.promise();
  }

  scaffold(scaffold) {
    return this.#scaffold[scaffold];
  }

  dxStore(scaffoldId, filter) {
    const self = this;
    const scaffold = self.scaffold(scaffoldId);
    let customFilter = [];

    // noinspection JSCheckFunctionSignatures
    return new DevExpress.data.CustomStore({
      key: scaffold.key,
      load(options) {
        if (options.customFilter) {
          customFilter = options.customFilter;
          return;
        }
        const deferred = $.Deferred();
        const _filter = [];
        const appendFilter = (filter) => {
          if (filter?.length > 0) {
            if (_filter.length > 0) {
              _filter.push("and");
            }
            _filter.push(filter);
          }
        }
        appendFilter(options.filter);
        appendFilter(filter);
        customFilter.length ? appendFilter(customFilter) : null;
        const body = {
          path: scaffoldId,
          filter: _filter
        }
        self.#just.callFunction('getDocuments', body).then((result) => {
          deferred.resolve(result);
        });
        return deferred.promise();
      },
      byKey(key) {
        const deferred = $.Deferred();
        getDoc(doc(self.#db, scaffoldId, key)).then((snap) => {
          deferred.resolve([snap.data()]);
        });

        return deferred.promise();
      },
      update(key, document) {
        const deferred = $.Deferred();
        let body = {
          document: document,
          scaffold: scaffoldId
        }
        if (key) body.key = key;
        self.#just.callFunction('createUpdateScaffoldDocument', body).then((data) => {
          deferred.resolve(data);
        });
        return deferred;
      },
      insert(document) {
        return this.update(undefined, document);
      }
    });
  }
}
