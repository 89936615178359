export class Control {
  #controls;
  #elements;
  constructor(just) {
    this.just = just;
    this.#controls = {};
    this.#elements = {};
  }
  add(struct, callback) {
    let element = $('<div>');
    let target = struct.target;
    let id = struct.id ? struct.id : this.just.util.uuid();
    let control_parts = struct.control.split("/");
    let control_path = control_parts[0];
    let control_name = control_parts[1];
    target ? typeof target === 'string' ? $(target).append(element) : target.append(element) : null;
    this.#elements[id] = element;
    import(`./Control/${control_path}/${control_name}.js`).then((control) => {
      this.#controls[id] = new control[control_name](struct.struct, element, this.just);
      if (callback) {
        callback(this.#controls[id]);
      }
      if (struct.onCreated) {
        struct.onCreated(this.#controls[id]);
      }
    });
    return id;
  }

  getControl(id) {
    return this.#controls[id];
  }

  getElement(id) {
    return this.#elements[id];
  }
}
