import {Control} from './Just/Control.js';
import {Util} from './Just/Util.js';
import {Theme} from './Just/Theme.js';
import {Auth} from './Just/Auth.js';
import {Database} from './Just/Database.js';

import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';


export class Just {
  constructor() {
    const self = this;
    self.control = new Control(self);
    self.util = Util;
    self.theme = Theme;
    self.theme.theme();

    //Revolve
    const config = {
      apiKey: "AIzaSyDdd9x71UI3lMDjoOc7E7bkDu4KzlPa69w",
      authDomain: "whiteboard.revolvess.com",
      projectId: "revolve-whiteboard",
      storageBucket: "revolve-whiteboard.appspot.com",
      messagingSenderId: "1052212005515",
      appId: "1:1052212005515:web:73a8dbcf7ce63efd7c228e"
    };

    //First Friends
    /*const config = {
      apiKey: "AIzaSyBQe7xN3165J81YbxK0ompxPCXDatHB0hE",
      authDomain: "first-friends-nursery-school.firebaseapp.com",
      projectId: "first-friends-nursery-school",
      storageBucket: "first-friends-nursery-school.appspot.com",
      messagingSenderId: "1052558777966",
      appId: "1:1052558777966:web:97cc47ecf8c85ceb5b107f"
    };*/


    self.app = initializeApp(config);
    self.auth = new Auth(self);
    self.db = new Database(self);
    //self.functions = getFunctions(self.app, "europe-west3");
    self.functions = getFunctions(self.app, "asia-southeast1");

    if (window.location.hostname === 'localhost') {
      // noinspection JSCheckFunctionSignatures
      connectFunctionsEmulator(self.functions, "localhost", "5003");
      //this.functions = getFunctions(this.app, "europe-west3");
      this.functions = getFunctions(this.app, "asia-southeast1");
    } else {
      this.functions = getFunctions(this.app, "https://whiteboard.revolvess.com");
      //this.functions = getFunctions(this.app, "https://first-friends-nursery-school.firebaseapp.com");
    }
  }

  callFunction(function_name, body) {
    let deferred = $.Deferred();
    const callFunction = httpsCallable(this.functions, function_name);
    callFunction(body).then((result) => {
      deferred.resolve(result.data);
    }).catch((error) => {
      const code = error.code;
      const message = error.message;
      const details = error.details;
      DevExpress.ui.notify(`Error ${code} - ${message}`, 'error', 5000);
      console.log(`Error ${code} - ${message}`, details);
    });
    return deferred;
  }
}
