export class Util {


  static uuid() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return (S4() + S4() + "-" + S4() + "-4" + S4().substring(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
  }

  static escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  static convertWildcardStringToRegExp(expression) {
    const terms = expression.split('*');
    let trailingWildcard = false;
    let expr = '';
    for (let i = 0; i < terms.length; i++) {
      if (terms[i]) {
        if (i > 0 && terms[i - 1]) {
          expr += '.*';
        }
        trailingWildcard = false;
        expr += Util.escapeRegExp(terms[i]);
      } else {
        trailingWildcard = true;
        expr += '.*';
      }
    }
    if (!trailingWildcard) {
      expr += '.*';
    }
    return new RegExp('^' + expr + '$', 'i');
  }

  static filterArray(array, expression) {
    const regex = Util.convertWildcardStringToRegExp(expression);
    return array.filter(function(item) {
      return regex.test(item);
    });
  }
}
