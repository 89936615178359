import {Just} from "./Just.js";
export class App {
  #just;
  #toolbar;
  #scaffolds;
  #content;
  #scaffoldGrid;
  constructor() {
    const version = '1.0.2.3';
    const just = new Just();
    const self = this;
    self.#just = just;

    just.control.add({
      control: "Base/Box",
      target: 'body',
      struct: {
        height: "100vh",
        direction: "col",
        items: [{
          baseSize: "auto",
          control: "Base/Toolbar",
          shrink: 0,
          onCreated(control) {
            self.#toolbar = control;
            just.auth.toolbar(self.#toolbar);
          },
          struct: {
            class: "wb-toolbar",
            items: [{
              location: 'before',
              template() {
                // noinspection HtmlUnknownTarget
                return $('<img width="30" style="margin-left: 5px;" src="favicon.ico"  alt="Whiteboard" />');
                //return $(`<img width="32" style="margin-left: 5px;" src="https://ffns.co.za/wp-content/uploads/2019/09/cropped-FF-favicon-32x32.jpg" alt="ffns" />`);
              },
              onClick() {
                self.home();
              }
            }, {
              location: 'after',
              template() {
                return $(`<div>v${version}</div>`);
              }
            }, {
              location: 'after',
              widget: 'dxButton',
              options: {
                icon: just.theme.theme()["icon"],
                onClick(e) {
                  just.theme.theme(just.theme.theme()["alt"]);
                  e.component.option("icon", just.theme.theme()["icon"]);
                }
              }
            }]
          }
        }, {
          baseSize: "100%",
          control: "Base/Box",
          onCreated(control) {
            self.#content = control;
            setTimeout(() => {
              self.#just.auth.init(() => {
                console.log('Authentication success');
                self.#just.db.connect().then((scaffolds) => {
                  self.#scaffolds = scaffolds;
                  self.#toolbar.add([{
                    location: 'before',
                    widget: 'dxButton',
                    options: {
                      icon: "codeblock",
                      onClick() {
                        self.#just.callFunction('updateAllScaffoldDocuments').then(() => {
                          DevExpress.ui.notify(`Scaffolds updated from source`, 'success', 5000);
                        });
                      }
                    }
                  }]);
                  self.home();
                });
              }, () => {
                console.log('Authentication failed');
              });
            }, 100);
          },
          struct: {
            height: "100%"
          }
        }]
      }
    });
  }
  home() {
    const self = this;
    const onItemClick = (e) => {
      self.openScaffold(e.itemData.id, [], 0);
    };
    self.#content.setBoxControl({
      baseSize: "100%",
      control: "Base/TileView",
      shrink: 0,
      onCreated(control) {
        control.items(self.scaffoldItems());
      },
      struct: {
        itemTemplate(itemData, itemIndex, itemElement) {
          itemElement.css('text-align', 'center');
          itemElement
            .append($(`<i style="font-size:56px;margin-top:3px" class="${itemData.icon}"></i>`))
            .append($(`<div style="text-align: center; position: absolute; bottom:3px; width:100px">${itemData.text}</div>`));
        },
        onItemClick
      }
    });
  }

  isTopScaffold(scaffoldId) {
    const scaffold = this.#just.db.scaffold(scaffoldId);
    let isParent = false;
    $.each(scaffold["foreign_keys"], (i, foreignKey) => {
      if (foreignKey["scaffold"] !== scaffoldId) {
        if (foreignKey["type"] === "parent") {
          isParent = true;
          return false;
        }
      }
    });
    return !isParent;
  }

  scaffoldItems(parentScaffoldId) {
    const self = this;
    const items = [];
    $.each(self.#scaffolds, (i, scaffold) => {
      if (!scaffold.menu) return;

      let isChild = false;
      if (parentScaffoldId) {
        $.each(scaffold["foreign_keys"], (ii, foreignKey) => {
          if (foreignKey.type === "parent" && foreignKey.scaffold === parentScaffoldId) {
            isChild = true;
            return false;
          }
        });
      }

      if ((self.isTopScaffold(scaffold.id) && !parentScaffoldId) || isChild) {
        items.push({
          id: scaffold.id,
          scaffoldId: scaffold.id,
          text: scaffold.label,
          icon: scaffold.menu.icon,
          order: scaffold.menu.order
        });
      }
    });
    items.sort((a, b) => a["order"] - b["order"]);
    return items;
  }

  scaffoldMenu(scaffoldId, path, selectedItem) {
    const self = this;
    const items = self.scaffoldItems(scaffoldId);
    const toolbarItems = [];
    const scaffold = self.#scaffolds[scaffoldId];
    let level = 0;

    toolbarItems.push({
      location: 'before',
      template() {
        const menuItems = [];
        const menuItem = {
          id: scaffoldId,
          text: scaffold.label,
          icon: scaffold.menu.icon,
        }
        menuItems.push(menuItem); //Current scaffold item

        const menuPath = [];
        let menuScaffoldId;
        $.each(path, (i, path) => {
          if (i % 2 !== 0) {
            level++;
            const scaffold = self.#scaffolds[menuScaffoldId];
            const menuItem = {
              id: i,
              text: path.display,
              path: [...menuPath],
              scaffoldId: menuScaffoldId,
              icon: scaffold.menu.icon,
              onClick() {
                menuItem.path.pop();
                self.openScaffold(menuItem.scaffoldId, menuItem.path, level-1);
              }
            }
            menuItems.push(menuItem);//Parent scaffold items
          } else {
            menuScaffoldId = path;
          }
          menuPath.push(path);//Parent scaffold items
        });

        if (selectedItem) {
          menuPath.push(scaffoldId);
          menuPath.push(selectedItem);
          $.each(items, (i, item) => {
            const scaffold = self.#scaffolds[item.id];
            const nextLevel = level + 1;
            const menuItem = {
              id: i,
              text: item.text,
              icon: scaffold.menu.icon,
              path: [...menuPath],
              onClick() {
                self.openScaffold(item.id, menuItem.path, nextLevel);
              }
            }
            menuItems.push(menuItem);//Child scaffold items
          });
        }

        return $("<div>").dxMenu({
          dataSource: menuItems
        });
      }
    });
    self.#scaffoldGrid.addToolbarItems(toolbarItems, 'scaffold');
  }

  openScaffold(scaffoldId, path, level) {
    const self = this;
    const selectedItem = self.#just.db.select(scaffoldId, level);
    const onSelectionChanged = (e) => {
      const item = {
        key: e.selectedRowKeys[0],
        display: self.#just.db.scaffoldDisplay(scaffoldId, e.selectedRowsData[0])
      };
      self.#just.db.select(scaffoldId, level, item);
      self.scaffoldMenu(scaffoldId, path, item, level);
    };
    let drawer;
    const gridStruct = () => {
      return {
        baseSize: "100%",
        control: `Scaffold/Grid`,
        onCreated(control) {
          self.#scaffoldGrid = control;
          const selectedItem = self.#just.db.select(scaffoldId, level);
          self.scaffoldMenu(scaffoldId, path, selectedItem, level);
        },
        struct: {
          scaffold: scaffoldId,
          path: path,
          level: level,
          selectedRowKeys: selectedItem ? selectedItem.key : undefined,
          //masterDetail: {
          //  enabled: true,
          //  template(container, info) {
          //    const struct = gridStruct();
          //    struct.target = container;
          //    self.#just.control.add(struct);
          //  }
          //},
          onOpenClick(e) {
            drawer.setDrawerControl({
              control: "Scaffold/Document",
              struct: {
                scaffoldId: scaffoldId,
                onIconClick() {
                  drawer.hide();
                },
                onSave() {
                  self.#scaffoldGrid.refresh();
                },
                key: e.row.key,
                data: e.row.data,
                store: self.#scaffoldGrid.store()
              }
            });
            drawer.show();
          },
          onSelectionChanged
        }
      }
    }
    self.#content.setBoxControl({
      baseSize: "100%",
      control: `Base/Drawer`,
      onCreated(control) {
        drawer = control;
      },
      struct: {
        class: "wb-document-drawer",
        opened: false,
        openedStateMode: "overlap",
        position: "right",
        viewControl: gridStruct()
      }
    });
  }
}