import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  setPersistence,
  browserLocalPersistence,
  connectAuthEmulator
} from "firebase/auth";

export class Auth {
  #auth;
  #provider;
  #instance;
  #toolbar;
  #just;
  #settings;
  #popupElement;
  #signInElement;
  #signInForm;
  constructor(just) {
    const self = this;
    self.#just = just;
    self.#provider = window.localStorage.getItem("provider");
    self.#settings = {
      url: window.location.href,
      handleCodeInApp: true
    };
    //self.#popupElement = $("<div>");
    //$(document.body).append(self.#popupElement);
  }

  auth() {
    return this.#auth;
  }

  init(success, reject) {
    const self = this;

    self.#auth = getAuth();

    if (window.location.hostname === 'localhost') {
      connectAuthEmulator(self.#auth, "http://localhost:9099");
    }
    self.provider();

    //if (isSignInWithEmailLink(self.#auth, window.location.href)) {
    //  const email = window.localStorage.getItem('emailForSignIn');
    //  signInWithEmailLink(self.#auth, email, window.location.href).then((result) => {
    //    console.log(result);
    //    window.localStorage.removeItem('emailForSignIn');
    //  });
    //}

    setPersistence(self.#auth, browserLocalPersistence).then(() => {
      if (self.#auth.currentUser) {
        self.resetToolbar(success, reject);
        success();
      } else {
        self.addSignInButton(success, reject);
        reject();
      }
    }).catch((error) => {
      console.log("Authentication initialization error", error);
      reject();
    });
  }

  provider() {
    const self = this;
    if (self.#provider === "email") {
      console.log(self.#provider);
    }
    if (self.#provider === "google") {
      self.#instance = new GoogleAuthProvider();
    }
    if (self.#provider === "microsoft") {
      self.#instance = new OAuthProvider('microsoft.com');
    }
  }

  signInWithEmail(success, reject) {
    const self = this;
    const email = window.localStorage.getItem('emailForSignIn');
    const askForEmail = () => {
      self.#just.control.add({
        control: "Base/Popup",
        target: self.#popupElement,
        struct: {
          title: "Sign-in with email link",
          contentTemplate: () => {
            const element = $("<div>");
            self.#just.control.add({
              control: "Base/Form",
              target: element,
              onCreated(control) {
                self.#signInForm = control;
              },
              struct: {
                labelMode: "static",
                items: ["email"]
              }
            });
            self.#just.control.add({
              control: "Base/Toolbar",
              target: element,
              struct: {
                items: [{
                  widget: "dxButton",
                  options: {
                    text: "Get link",
                    stylingMode: "outlined",
                    onClick: () => {
                      const data = self.#signInForm.dxForm().option("formData");
                      const email = data["email"];
                      sendSignInLinkToEmail(self.#auth, email, self.#settings).then((result) => {
                        console.log(result);
                        window.localStorage.setItem('emailForSignIn', email);
                      });
                    }
                  }
                }]
              }
            });
            return element;
          },
          width: 300,
          height: 200,
          visible: true,
          position: {
            at: "center",
            my: "center",
            of: window
          }
        }
      });
      console.log("Ask for email");
    };
    if (email) {
      sendSignInLinkToEmail(self.#auth, email, self.#settings).then((result) => {
        console.log(result);
        window.localStorage.setItem('emailForSignIn', email);
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        askForEmail();
      });
    } else {
      askForEmail();
    }
  }

  signIn(success, reject, provider) {
    const self = this;
    self.#provider = provider;
    window.localStorage.setItem("provider", provider);
    self.provider();

    if (provider === "email") {
      self.signInWithEmail(success, reject);
    } else {
      signInWithPopup(self.#auth, self.#instance).then((result) => {
        self.resetToolbar(success, reject);
        success(result);
      }).catch((error) => {
        console.log("Authentication sign-in error", error);
        reject(error);
      });
    }
  }

  uid() {
    return this.#auth.currentUser.uid;
  }

  signOut() {
    this.#auth.signOut().then(() => {
      location.reload();
    });
  }

  toolbar(toolbar) {
    this.#toolbar = toolbar;
  }

  validate() {
    return !!this.#auth.currentUser;
  }

  addSignInButton(success, reject) {
    const self = this;
    if (self.#toolbar) {
      self.#toolbar.add({
        id: 'sign-in',
        location: 'after',
        template() {
          self.#signInElement = $("<div>");
          //const emailElement = $("<div style='float:left;cursor:pointer'>");
          const googleElement = $("<div style='float:left;cursor:pointer'>");
          const microsoftElement = $("<div style='float:left;cursor:pointer'>");
          //self.#signInElement.append(emailElement);
          self.#signInElement.append(googleElement);
          self.#signInElement.append(microsoftElement);
          //const emailTextElement = $('<div style="float:left;margin-top:2px;margin-right:6px;">Sign-in with Email</div>');
          //emailElement.append(emailTextElement);
          const googleSvgElement = $('<div style="float:left;height:20px;margin-right:6px;"><svg height="100%" viewBox="0 0 20 20" width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z" fill="#4285F4"></path><path d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z" fill="#34A853"></path><path d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z" fill="#FBBC05"></path><path d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z" fill="#EA4335"></path></svg></div>');
          const googleTextElement = $('<div style="float:left;margin-top:2px;margin-right:6px;">Sign-in with</div>');
          googleElement.append(googleTextElement);
          googleElement.append(googleSvgElement);
          const microsoftSvgElement = $('<div style="float:left;height:20px;margin-right:6px;"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><title>MS-SymbolLockup</title><rect x="1" y="1" width="9" height="9" fill="#f25022"/><rect x="1" y="11" width="9" height="9" fill="#00a4ef"/><rect x="11" y="1" width="9" height="9" fill="#7fba00"/><rect x="11" y="11" width="9" height="9" fill="#ffb900"/></svg></div>');
          const microsoftTextElement = $('<div style="float:left;margin-top:2px;margin-right:6px;">or</div>');
          microsoftElement.append(microsoftTextElement);
          microsoftElement.append(microsoftSvgElement);
          //emailElement.on("click", () => {
          //  self.signIn(success, reject, "email");
          //});
          googleElement.on("click", () => {
            self.signIn(success, reject, "google");
          });
          microsoftElement.on("click", () => {
            self.signIn(success, reject, "microsoft");
          });
          return self.#signInElement;
        }
      });
    } else {
      setTimeout(() => {
        self.addSignInButton(success, reject);
      }, 100);
    }
  }

  resetToolbar() {
    let _this = this;
    if (_this.#toolbar) {
      if (_this.validate()) {
        _this.#toolbar.remove(['sign-in']);
        _this.#toolbar.add([{
          id: 'profile',
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: "user",
            onClick() {
            }
          }
        },{
          id: 'sign-out',
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: "runner",
            onClick() {
              _this.signOut();
            }
          }
        }]);
      }
    }
  }

  /*getProfileImageElement() {
    let _this = this;
    let photoURL = _this.#auth.currentUser.photoURL;
    return $('<div>').append($(`<img src="${photoURL}" alt="Profile picture" />`).width(28).height(28).css('border-radius','14px'));
  }*/
}
