export class Theme {
  static themes = {
    dark: {
      dx_theme: "dx-theme-dark",
      theme: "theme-dark",
      icon: "sun",
      rel: "css/dx.material.blue.dark.compact.css",
      alt: "light"
    },
    light: {
      dx_theme: "dx-theme-light",
      theme: "theme-light",
      icon: "moon",
      rel: "css/dx.material.blue.light.compact.css",
      alt: "dark"
    }
  }

  static theme(theme) {
    if (!theme) {
      theme = window.localStorage.getItem("theme");
    }
    if (!theme) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = "dark";
      } else {
        theme = "light";
      }
    }
    window.localStorage.setItem("theme", theme);
    let themeLink = $(`#${this.themes[theme].dx_theme}`);
    let altThemeLink = $(`#${this.themes[this.themes[theme].alt].dx_theme}`);
    if (!themeLink[0]) {
      themeLink = $(`<link rel="stylesheet" type="text/css" href="${this.themes[theme].rel}" id="${this.themes[theme].dx_theme}"/>`);
      $('body').append(themeLink);
    } else {
      themeLink.removeAttr('disabled');
    }
    if (altThemeLink[0]) {
      altThemeLink.attr('disabled', 'disabled');
    }
    return this.themes[theme];
  }
}
